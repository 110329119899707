<template>
  <div>
    <!--    <div class="sessionList">-->
    <!--      <div v-for="(session_item,index) in sessionList" :key="index">-->
    <!--        <div @click="getSessionEvents(session_item.session_name)" class="session-item">-->
    <!--          <div style="margin-left: 45px;margin-top: 45px">-->
    <!--            <img src="../../../assets/icon_session_ungoing.png" style="width: 93px;height: auto">-->
    <!--          </div>-->
    <!--          <p class="session-name">{{ session_item.session_name }}</p>-->
    <!--          <div class="session-item-bottom">-->
    <!--            <div style="display: flex;flex-direction: row;align-items: center;position: absolute;bottom: 45px">-->
    <!--              <div style="display: flex;flex-direction: row;align-items: center">-->
    <!--                <img src="../../../assets/calender.png" style="width: 12px;height: auto">-->
    <!--                <p class="session-time" style="margin-left: 10px">{{ session_item.session_time }}</p>-->
    <!--                <img src="../../../assets/arrow.png" style="margin-left: 20px;width: 19px;height: 19px">-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div style="color: #666; font-size: 16px; padding: 10px; margin: 10px 10px; background-color: #f5f5f5; border-radius: 4px;">
      Note: All test-related timestamps are displayed in UTC time. Please be mindful of converting between UTC and your local time zone when checking test schedules.
    </div>
    <div style="width:100%;margin:40px auto">
      <div v-if="showChinese != 'english'" class="student-session-title">我的项目</div>
      <div class="session-title-1">MY EVENT</div>
      <div class="my-events">
        <div v-for="(item,index) in my_events" :key="index">
          <div class="events-item">
            <div style="position: absolute;z-index: 1;width: 100%;height:100%">
              <img src="../../../assets/event_bg.png" style="object-fit: cover; width: 100%;height:100%;background-repeat: no-repeat;background-color: transparent;background-size: cover;min-width: 100%;min-height: 100%;">
            </div>
            <div style="z-index: 2;padding: 0px 36px ;">

              <img src="../../../assets/sessionLeftImg.png" class="leftImgStyle">

              <div class="project_name">{{ item.project_ename }}</div>

              <div class="event_name">{{ item.name }}   <span class="type_label">{{item.type + ' Test'}}</span></div>

              <!-- <div class="event_info">Put your professional career in full force with courses on learn from us. -->
                <!-- Gain new knowledge & skills</div> -->



              <div style="position: relative;  margin-left: 150px;margin-top: 5px;height: 60px;display: flex;flex-direction: row">
                <div style="display: flex;flex-direction: row;align-items: center;">
                  <div style="font-family: Montserrat-SemiBold;color: #273161;font-size: 18px;font-weight: 600">{{$t('m.kaishishijian')}}</div>

                  <div v-if="showChinese != 'english'" style="font-family: Montserrat-SemiBold;color: #F18601;font-size: 18px;font-weight: 600;margin-left: 10px">
                    {{ formatSessionDate(item.event_start_time) }}
                  </div>

                  <div v-if="showChinese == 'english'" style="font-family: Montserrat-SemiBold;color: #F18601;font-size: 18px;font-weight: 600;margin-left: 10px">
                    {{ formatSessionDate1(item.event_start_time ) }}
                  </div>

                </div>
                <div
                    style="top: 50%;transform: translateY(-50%);display: flex;flex-direction: row;align-items: center;position: absolute;right: 0">
                  <div class="event-status">{{item.status}}</div>
                  <div class="event-entry" style="margin-left: 10px" @click="goExam(item)">{{$t('m.jinrukaochang')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showChinese != 'english'" class="student-session-title">其它进行项目</div>
      <div class="session-title-1">OTHER ONGOING EVENTS</div>
      <div class="my-events">
        <div v-for="(item,index) in my_other_events" :key="index">
          <div class="events-item">
            <div style="position: absolute;z-index: 1">
              <img src="../../../assets/event_bg.png" style="width: 100%;height: 176px;">
            </div>
            <div style="z-index: 2;padding: 0px 36px ;">
              <div class="event_name">{{ item.name }}</div>
              <div style="position: relative;margin-top: 10px;height: 60px;display: flex;flex-direction: row">
                <div style="display: flex;flex-direction: row;align-items: center;">
                  <div style="font-family: Montserrat-SemiBold;color: #273161;font-size: 18px;font-weight: 600">{{$t('m.kaishishijian')}}</div>
                  <div v-if="showChinese != 'english'" style="font-family: Montserrat-SemiBold;color: #F18601;font-size: 18px;font-weight: 600;margin-left: 10px">
                    {{ formatSessionDate(item.event_start_time) }}
                  </div>

                  <div v-if="showChinese == 'english'" style="font-family: Montserrat-SemiBold;color: #F18601;font-size: 18px;font-weight: 600;margin-left: 10px">
                    {{ formatSessionDate1(item.event_start_time) }}
                  </div>
                </div>
                <!--                <div-->
                <!--                    style="top: 50%;transform: translateY(-50%);display: flex;flex-direction: row;align-items: center;position: absolute;right: 0">-->
                <!--                  <div class="event-status">状态</div>-->
                <!--                  <div class="event-entry" style="margin-left: 10px">进入考场</div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkIsUploadTest, getExerciseTestTemplate, getSessionEvents,checkUserInvigilate,getExamReadyTime ,checkExamInfo} from '../../../api/index'
import {getApplicationEvents, getApplicationExtraEvents,getProjectCode, getUserId,setTestEvent,getProjectLanguage ,setRemoteProjectCode,setIsGroup,setAppId} from "../../../utils/store";
import {formatDate,formatDateUTC} from "../../../utils/date";
import '../../../assets/common/font.css'
export default {
  name: "SessionList",
  data() {
    return {
      project_code: '',
      my_events: [],
      my_other_events: [],
      sessionList: [],
      login_application_extra_events: [],
      login_application_events: [],
      exam_entry_before_time: 0,
      exam_prohibit_time_when_start: 0,
      showChinese:getProjectLanguage()

    }
  },
  mounted() {
    this.login_application_extra_events = getApplicationExtraEvents()
    this.login_application_events = getApplicationEvents()
    this.project_code = getProjectCode()
    this.fetchData()
    this.getReadyTime()
  },
  methods: {
    getReadyTime() {
      getExamReadyTime().then((res) => {
        let result = res.data.data;
        this.exam_entry_before_time = result.exam_entry_before_time * 60 * 1000;
        this.exam_prohibit_time_when_start =
          result.exam_prohibit_time_when_start * 60 * 1000;
      });
    },
    goExam(item) {


      console.log(1111,item)
      
      var user_group_id = ''

      setIsGroup('false')

      if (item.event_type == 'group'){
        user_group_id = item.user_group_id

        var app_id = item.app_id
        setAppId(app_id)
        setIsGroup('true')
      }



      var startTime = item.event_start_time
      

      setRemoteProjectCode(item.project_code)
      var temp1 = 0


      console.log(123123,getProjectCode())

      // if (this.showChinese == 'english'){


      //   var shiqi = this.formatSessionDate1(startTime)

      //   startTime = new Date(shiqi).getTime()

      //   var currentTime = this.getUTCTime(0).getTime()

      //   temp1 = parseInt(startTime)

      //   console.log('111aaa:'+currentTime)

      // }else{

        var currentTime = new Date().getTime()
        

        temp1 = parseInt(startTime) * 1000
      // }
      
      console.log(1111,currentTime)
      //TODO:为了测试，去掉时间判断
      var test_event = item.name
      if (item.type == 'Formal'){
        if (temp1 - this.exam_entry_before_time > currentTime) {
          this.$message({
            message:
              "The test has not started. You can only enter 15 minutes before the start time",
            type: "warning",
          });
          return;
        }
        //考试开始15分钟，无法进入考场
        if (temp1 + this.exam_prohibit_time_when_start < currentTime) {
          this.$message({
            message:
              "The entry time is already over. You can not enter into the test room now.",
            type: "warning",
          });
          return;
        }
      }

      

      getExerciseTestTemplate(item.name, getProjectCode(),item.type,test_event).then((res) => {
        if (res.data.code == 0){
          var result = res.data.data
          var template_id = result._id.$id
          this.doCheck(template_id,temp1,item.type,test_event,user_group_id)
        }else{
          this.$message({
            message: res.data.msg,
            type: 'warning'
          })
        }
        
      })
    },
    doCheck(template_id,timeStr,event_type,test_event,user_group_id) {
      var student_id = getUserId()
      if (user_group_id != ''){
        student_id = user_group_id;
      }
      checkIsUploadTest(template_id, student_id).then((res) => {
        var result = res.data
        // result= false
        if (result) {
          this.$alert(this.$t('m.wanchengle'), 'Tip', {
            confirmButtonText: 'OK',
            cancelButtonText:'Cancel',
            callback: () => {
            }
          });
        } else {
          setTestEvent(test_event)


          if (this.showChinese == 'english'){

            checkUserInvigilate(getProjectCode(),getUserId()).then((res) => {
              console.log(1111,res)
              //true 需要监考
              if (res.data == true){
                this.$router.push({
                  path: '/ready/exam',
                  query: {
                    template_id: template_id,
                    start_time:timeStr,
                    event_type:event_type
                  }
                })
              }else{

                this.$router.push({
                  path: "/exam",
                  query: {
                    template_id: template_id,
                    invigilateStatus:true
                  },
                });
              }
            })
            
          }else{
            this.checkTip(template_id,timeStr,event_type)
          }

          
        }
      })
    },

    checkTip(template_id,timeStr,event_type){

      var info = '请注意：考生在进入考试前需确认下线所有社交软件，并关闭翻译软件及自动取词翻译功能。若在考试过程中，系统监考屏幕录制到使用社交软件聊天，使用翻译软件或翻译器取词翻译的情况，经监考员核实后将直接取消考生成绩并通报学校。'

      this.$confirm(info, '', {
          confirmButtonText: '我已知悉并确认关闭所有社交及翻译软件',
          // cancelButtonText: '取消',
          showCancelButton: false
          // type: 'warning'
        }).then(() => {
          //判断是否需要监考

          checkExamInfo(getProjectCode(),getUserId(),template_id,info).then((res) => {
          })

          checkUserInvigilate(getProjectCode(),getUserId()).then((res) => {
            console.log(1111,res)
            //true 需要监考
            if (res.data == true){
              this.$router.push({
                path: '/ready/exam',
                query: {
                  template_id: template_id,
                  start_time:timeStr,
                  event_type:event_type
                }
              })
            }else{

              this.$router.push({
                path: "/exam",
                query: {
                  template_id: template_id,
                  invigilateStatus:true
                },
              });
            }
          })

        }).catch(() => {
                 
        });
    },


    formatSessionDate(time) {
      var time_1 = JSON.parse(time) * 1000;
      var date = new Date(time_1)
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },

    formatSessionDate1(time) {
      var time_1 = JSON.parse(time) * 1000;
      var date = new Date(time_1)
      return formatDateUTC(date, 'yyyy-MM-dd hh:mm')
    },
    getUTCTime(data){
      var time=new Date(); //获取时间
      // 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
      var dataOffset=new Date().getTimezoneOffset()*60000
      // 获取格林威治时间 
      var utc=time.getTime()+dataOffset;  // 两个时间戳
      // 拿格林威治时间去反推指定地区时间    
      var newTime=utc+3600000*data;  // 
      var times=new Date(newTime);
      return times
    },	
    fetchData() {
      this.getSessionEvents()
      // getSessionList(this.project_code).then((res) => {
      //   var result = res.data.data
      //   var keys = Object.keys(result)
      //   for (var i = 0; i < keys.length; i++) {
      //     var key = keys[i]
      //     var session = {
      //       session_name: key,
      //       session_time: result[key]
      //     }
      //     this.sessionList.push(session)
      //   }
      //   var first_session_name = this.sessionList[0]['session_name']
      //   this.getSessionEvents(first_session_name)
      // })
    },
    getSessionEvents() {
      this.my_other_events = []
      this.my_events = []


      var foreign = 'false'

      if (this.showChinese == 'english'){
        foreign = 'true'
      }

      getSessionEvents(getUserId(),foreign).then((res) => {
        var result = res.data.data
        console.log(1111,this.login_application_events)

        var keys = Object.keys(result)

        for (var i = 0; i < keys.length; i++) {
          var key = keys[i]
      
          var details = result[key]

          for (var j = 0; j < details.length; j++) {

              if (this.showChinese == 'english'){

                if (details[j].status == '未开始'){
                  details[j].status = 'Not started'
                }
                if (details[j].status == '进行中'){
                  details[j].status = 'Testing'
                }
                if (details[j].status == '已完成'){
                  details[j].status = 'Completed'
                }
                if (details[j].status == '已结束'){
                  details[j].status = 'Has ended'
                }

              }
              
              this.my_events.push(details[j])
          }

        }

        // if (session == 'Economics') {
        //   this.my_events = result[session]
        // } else {
          // if (this.login_application_events) {
          //   for (var i = 0; i < this.login_application_events.length; i++) {
          //     var event = this.login_application_events[i]
          //     if (result[event]) {//有数据
          //       var temp = result[event]
          //       for (var m = 0; m < temp.length; m++) {
          //         this.my_events.push(temp[m])
          //       }
          //     }
          //   }
          // }
        // }

        if (this.login_application_extra_events) {
          for (var j = 0; j < this.login_application_extra_events.length; j++) {
            var event1 = this.login_application_extra_events[j]
            if (result[event1]) {//有数据
              var temp1 = result[event1]
              for (var n = 0; n < temp1.length; n++) {
                this.my_other_events.push(temp1[n])
              }
            }
          }
        }
      })
    }
  }
}
</script>


<style scoped>

.event-entry {
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  padding: 2px 5px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  background: #4468EC;
  border-radius: 5px;
  border: 2px solid #4468EC;
}

.leftImgStyle {
  position: absolute;
  height:100px;
  align-items: center;
  margin-left: 0px;
  left: 10px;
  margin-top: 30px;
  /* background-color: red; */
}
.event-status {
  font-weight: 600;
  font-size: 18px;
  padding: 2px 15px;
  color: #4468EC;
  background: white;
  border-radius: 5px;
  border: 1px solid #4468EC;
}

.event_name {
  font-family: Montserrat-SemiBold;
  font-size: 40px;
  margin-left: 150px;
  font-weight: 900;
  margin-top: 15px;
  color: #1B213D;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.project_name {
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 150px;
  font-weight: 600;
  color: #1B213D;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.event_info {
  font-family: Montserrat-SemiBold;
  font-size: 15px;
  margin-left: 150px;
  font-weight: 900;
  color: #A7ABBE;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.events-item {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 176px;
  margin-right: 45px;
  margin-bottom: 30px;
}

.my-events {
  width: calc(100% - 40px);
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 45px;
}

.student-session-title {
  margin-left: 20px;
  font-weight: 600;
  color: #CACDDF;
  font-size: 18px;
  font-family: Montserrat-SemiBold;
}

.session-title-1 {
  margin-left: 20px;
  margin-top: 10px;
  font-family: Montserrat-SemiBold;
  font-weight: 800;
  color: #1B213D;
  font-size: 32px;
}

.session-name {
  font-size: 28px;
  font-weight: 600;
  color: white;
  margin: 10px 0 0 45px;
  font-family: Montserrat-SemiBold;
}

.session-item {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 340px;
  height: 285px;
  background-image: url("../../../assets/session_nav_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.session-item-bottom {
  position: relative;
  height: 110px;
  margin-top: 30px;
  padding-left: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.session-time {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #F18601;
  font-weight: 600;
  font-family: Montserrat-SemiBold;
}

.sessionList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.type_label{
  font-size:18px;
  margin-left:10px;
}

</style>